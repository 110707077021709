import { RouteConfigType } from "@energylab/shared-components/routes";

const locales = ["nl", "fr", "en"];
const defaultLocale = "nl";

export const routeConfig: RouteConfigType = {
    locales,
    defaultLocale,
    redirects: [],
    hideNavigationWhenLoggedOut: true
};
