import { INITIAL_CONFIG_STATE } from "@energylab/shared-components/redux/reducers/configReducer";
import { INITIAL_MAIN_STATE } from "@energylab/shared-components/redux/reducers/mainReducer";
import { INITIAL_AUTH_STATE } from "@energylab/shared-components/redux/reducers/authReducer";
import { config } from "../config";
import{ getPublicRoutes, getRoutes } from "@energylab/shared-components/routes"
import {privateRoutes, privateRoutesNoLocale, publicRoutes, publicRoutesNoLocale} from "../routes/content"

export const createInitialStore = () => {
    return {
        auth: INITIAL_AUTH_STATE,
        config: {
            ...INITIAL_CONFIG_STATE,
            ...config,
            routeConfig: {
                locales: config.routeConfig.locales,
                defaultLocale: config.routeConfig.defaultLocale,
                routes: {
                    private: getRoutes(privateRoutes, privateRoutesNoLocale),
                    public: getPublicRoutes(publicRoutes, publicRoutesNoLocale)
                },
                hideNavigationWhenLoggedOut: true
            }
        },
        main: INITIAL_MAIN_STATE
    };
};
