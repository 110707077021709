import { PasswordConfig } from "@energylab/shared-components/constants/config/passwordConfig";

export const passwordConfig: PasswordConfig = {
    requireOldPassword: true,
    minLength: 12,
    requireLetter: true,
    requireUpperCase: true,
    requireLowerCase: true,
    requireNumber: true,
    requireSpecialCharacter: true,
    allowedSpecialCharacters: [
        ".",
        ":",
        "+",
        "-",
        "_",
        "!",
        "#",
        "@",
        "&",
        "%",
        "£",
        "$",
        "€",
        "*",
        "|",
        "<",
        ">",
        "?"
    ],
    lockOut: {
        enabled: false,
        time: 0,
        attempts: 10
    }
};
