// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("./ArialCE-Regular.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("./ArialCE-Bold.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("./ArialCE-Black.ttf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@font-face {
    font-family: "arialCE";
    src:  url(${___CSS_LOADER_URL_REPLACEMENT_0___}) format('truetype');
    font-weight: 400;
}

@font-face {
    font-family: "arialCE";
    src:  url(${___CSS_LOADER_URL_REPLACEMENT_1___}) format('truetype');
    font-weight: 600;
}

@font-face {
    font-family: "arialCE";
    src:  url(${___CSS_LOADER_URL_REPLACEMENT_2___}) format('truetype');
    font-weight: 800;
}`, "",{"version":3,"sources":["webpack://./src/style/fonts/arialCE/arialCE.css"],"names":[],"mappings":"AAAA;IACI,sBAAsB;IACtB,gEAAqD;IACrD,gBAAgB;AACpB;;AAEA;IACI,sBAAsB;IACtB,gEAAkD;IAClD,gBAAgB;AACpB;;AAEA;IACI,sBAAsB;IACtB,gEAAmD;IACnD,gBAAgB;AACpB","sourcesContent":["@font-face {\n    font-family: \"arialCE\";\n    src:  url('./ArialCE-Regular.ttf') format('truetype');\n    font-weight: 400;\n}\n\n@font-face {\n    font-family: \"arialCE\";\n    src:  url('./ArialCE-Bold.ttf') format('truetype');\n    font-weight: 600;\n}\n\n@font-face {\n    font-family: \"arialCE\";\n    src:  url('./ArialCE-Black.ttf') format('truetype');\n    font-weight: 800;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
