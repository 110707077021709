import { LocalisedRouteObject, NavigateToLocale } from "@energylab/shared-components/routes";
import { lazy } from "react";
const ContentCardOverviewContainer = lazy(() => import("@energylab/shared-components/modules/benefits/contentCardOverview/contentCardOverviewContainer"));
const ContentContainer = lazy(() => import("@energylab/shared-components/modules/pages/content/contentContainer"));
const WidgetTypeFormContainer = lazy(() => import("../components/widgetTypeForm/WidgetTypeFormContainer"))

export const privateRoutes: LocalisedRouteObject[] = [
    {
        path: "morevitality-nl",
        element: <ContentContainer type="morevitality_nl" />
    },
    {
        path: "morevitality-be",
        element: <ContentContainer type="morevitality_be" />
    },
    {
        path: "wq",
        element: (
            <ContentCardOverviewContainer
                introKey="wq"
                metaDataKey="wq"
                contentType="shortTrack"
            />
        )
    },
    {
        path: "vitalityboost-faq",
        element: <ContentContainer type="vitalityboostfaq" />
    },
    {
        path: "energyjourneyevents",
        element: <ContentContainer type="energyjourneyevents" />
    },
    {
        path: "human-energy-scan",
        element: <WidgetTypeFormContainer/>
    }
];

export const privateRoutesNoLocale: LocalisedRouteObject[] = [
    {
        path: "vitalityboost",
        element: <NavigateToLocale />
    }
];

export const publicRoutes: LocalisedRouteObject[] = [
    {
        path: "vitalityboost-faq",
        element: <ContentContainer type="vitalityboostfaq" />
    },
    {
        path: "human-energy-scan",
        element: <WidgetTypeFormContainer/>
    },
    {
        path: "human-energy-scan-info",
        element: <ContentContainer type="human-energy-scan-info" />
    }
];

export const publicRoutesNoLocale: LocalisedRouteObject[] = [
    {
        path: "vitalityboost",
        element: <NavigateToLocale />
    },
];