import { SocialConfig } from "@energylab/shared-components/constants/config/socialConfig";

export const socialConfig: SocialConfig = {
    disableInstagram: false,
    numberOfInstagramPhotos: 6,
    disableTwitter: true,
    disableFacebook: true,
    disableLinkedIn: false,
    disableOnChallenCard: false,
    hideFacebookOnCommunity: true,
    hideTwitterOnCommunity: false,
    hideInstagramOnCommunity: false,
    hideLinkedInOnCommunity: true
};
