import { minYearOld } from "environment";
import { FormStructureScheme } from "@energylab/shared-components/components/UI/form/formBuilderManager/types";
import { nationalities } from "@energylab/shared-components/constants/nationalities";
import { FormatMessage } from "@energylab/shared-components/components/formatMessage/formatMessage";
import { routeConfig } from "./config/routeConfig";
import { sex } from "./sex";
import { roles } from "./roles";
import { checkMinBirthDateCreator } from "@energylab/shared-components/utils/checkMinBirthDate";

export const profileStructure: FormStructureScheme = {
    type: "form",
    formElements: [
        {
            type: "row",
            property: "names",
            formElements: [
                {
                    title: "auth.fields.givenName",
                    placeholder: "auth.fields.givenName",
                    type: "text",
                    property: "givenName",
                    required: true
                },
                {
                    title: "auth.fields.familyName",
                    placeholder: "auth.fields.familyName",
                    type: "text",
                    property: "familyName",
                    required: true
                }
            ]
        },
        {
            title: "auth.fields.birthDate",
            type: "date",
            property: "birthDate",
            required: true,
            disabled: false,
            validationRules: [
                {
                    validatorBuilder: checkMinBirthDateCreator(minYearOld)
                }
            ]
        },
        {
            title: "auth.fields.selectedLanguage",
            type: "select",
            options: routeConfig.locales.map(language => (
                {
                    title: <FormatMessage id={`language.${language}`} />,
                    value: language
                }
            )),
            property: "locale",
            required: false
        },
        {
            title: "auth.fields.sex",
            type: "radio",
            options: sex,
            property: "sex",
            required: true
        },
        {
            title: "form.nationality",
            type: "select",
            options: nationalities.map(nat => (
                {
                    title: <FormatMessage id={`nationality.${nat.code}`} />,
                    value: nat.code
                }
            )),
            property: "nationality",
            required: true
        },
        {
            title: "form.workArea",
            type: "select",
            options: roles.map(role => (
                {
                    title: <FormatMessage id={`countries.${role}`} />,
                    value: role
                }
            )),
            property: "role",
            required: true
        }
    ]
};
