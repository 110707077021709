import { NavbarConfig } from "@energylab/shared-components/constants/config/navbarConfig";

export const navbarConfig: NavbarConfig = {
    navbarLogo: "https://res.cloudinary.com/energylab/image/upload/v1628168295/ing/E2021_0182_My_vitality_logo_en_ikoontjes.png",
    navbarLogoNotLoggedIn: "https://res.cloudinary.com/energylab/image/upload/v1632491889/ing/MyVitality_logo.png",
    fullWidth: false,
    navbarItems:[
        {
            id: "dashboard",
            translationKey: "menuItem.dashboard",
            redirectTo: {
                path: "dashboard"
            }
        },
        {
            id: "challenges",
            translationKey: "menuItem.challenges",
            redirectTo: { path: "challenges" }
        },
        {
            id: "events",
            translationKey: "menuItem.events",
            redirectTo: { path: "events" }
        },
        {
            id: "news",
            translationKey: "menuItem.news",
            redirectTo: { path: "news" }
        },
        {
            id: "morevitality-be",
            translationKey: "menuItem.morevitality.be",
            redirectTo: { path: "morevitality-be" },
            roles: ["Belgium"]
        },
        {
            id: "morevitality-nl",
            translationKey: "menuItem.morevitality.nl",
            redirectTo: { path: "morevitality-nl" },
            roles: ["Netherlands"]
        },
        {
            id: "benefits",
            translationKey: "menuItem.benefits",
            redirectTo: { path: "benefits" }
        },
        {
            id: "vitalityboost",
            translationKey: "menuItem.vitalityboost",
            redirectTo: { path: "vitalityboost" },
            roles: ["Belgium"]
        },
        {
            id: "human-energy-scan",
            translationKey: "menuItem.humanenergyscan",
            redirectTo: { path: "human-energy-scan" },
            roles: ["Belgium"]
        },
        {
            id: "energyjourneyevents",
            translationKey: "menuItem.energyjourneyevents",
            redirectTo: { path: "energyjourneyevents" },
            roles: ["Belgium"]
        }
    ]
};
