import { SeoConfig } from "@energylab/shared-components/constants/config/seoConfig";

export const seo: SeoConfig = {
    pageTitle: "My vitality",
    title: {
        nl: "ING",
        fr: "ING",
        en: "ING"
    },
    description: {
        nl: "",
        fr: "",
        en: ""
    },
    image: "",
    site: ""
};
