import { ContentConfig } from "@energylab/shared-components/constants/config/contentConfig";

export const contentConfig: ContentConfig = {
    showAuthor: true,
    useThemes: true,
    previewType: "card",
    enablePageBanner: true,
    pageBannerImage: "https://res.cloudinary.com/energylab/image/upload/v1564991904/g4s/E2019_0339_About_pagina_banner_2000x645px4.jpg",
    pageBannerOverlay: true,
    alignment: "left"
};
