import { leaderboardConfig } from "@energylab/shared-components/constants/config/leaderboardConfig";
import { ConfigReducerState } from "@energylab/shared-components/redux/reducers/configReducer";
import { getBrowserLocale } from "@energylab/shared-components/utils/browserLocale";
import { dashboardPlacesConfig } from "@energylab/shared-components/constants/config/dashboardPlacesConfig";
import { ambassadorSettingsConfig } from "./constants/config/ambassadorSettingsConfig";
import { articleTagsConfig } from "./constants/config/articleTagsConfig";
import { auth } from "./constants/config/authConfig";
import { challengeConfig } from "./constants/config/challengeConfig";
import { coinsConfig } from "./constants/config/coinsConfig";
import { commentsConfig } from "./constants/config/commentsConfig";
import { contactConfig } from "./constants/config/contactConfig";
import { contentConfig } from "./constants/config/contentConfig";
import { dailyOverviewConfig } from "./constants/config/dailyOverviewConfig";
import { dashboardSettingsConfig } from "./constants/config/dashboardSettingsConfig";
import { departmentsConfig } from "./constants/config/departmentsConfig";
import { eventsConfig } from "./constants/config/eventsConfig";
import { footerConfig } from "./constants/config/footerConfig";
import { galleryConfig } from "./constants/config/galleryConfig";
import { likesConfig } from "./constants/config/likesConfig";
import { logoutConfig } from "./constants/config/logoutConfig";
import { navbarConfig } from "./constants/config/navbarConfig";
import { newUsersConfig } from "./constants/config/newUsersConfig";
import { nicknameConfig } from "./constants/config/nicknameConfig";
import { notificationsConfig } from "./constants/config/notificationsConfig";
import { passwordConfig } from "./constants/config/passwordConfig";
import { profileConfig } from "./constants/config/profileConfig";
import { providers } from "./constants/config/providersConfig";
import { registerConfig } from "./constants/config/registerConfig";
import { resetPasswordConfig } from "./constants/config/resetPasswordConfig";
import { routeConfig } from "./constants/config/routeConfig";
import { seo } from "./constants/config/seoConfig";
import { socialConfig } from "./constants/config/socialConfig";
import { socialShareConfig } from "./constants/config/socialShareConfig";
import { whiteListSignUpConfig } from "./constants/config/whiteListSignUpConfig";

export const config: ConfigReducerState = {
    password: passwordConfig,
    registerConfig,
    profileConfig,
    enableCountry: false,
    departmentsConfig,
    hideSocialLogin: true,
    hideSocialLinks: true,
    dashboardTopPadding: "100px",
    socialConfig,
    ambassadorImage: "",
    auth,
    commentsConfig,
    eventsConfig,
    contentConfig,
    contactConfig,
    companyLogo: "https://res.cloudinary.com/energylab/image/upload/v1632491889/ing/MyVitality_logo.png",
    companyName: "Your Best Self",
    defaultTimezone: "Europe/Brussels",
    defaultCountry: "Belgium",
    defaultNationality: "BEL",
    coinsConfig,
    socialTags: [],
    likesConfig,
    webshopConfig: {
        enabled: false,
        shopName: ""
    },
    tourConfig: {
        teamVisualizationAsChallengeProgress: true
    },
    notificationsConfig,
    providers,
    jumbotronImage: "",
    localeDateFormat: "DD/MM/YYYY",
    routeConfig,
    nationalities: [],
    selectedLanguage: getBrowserLocale(routeConfig.defaultLocale, routeConfig.locales),
    seo,
    uploadPreset: "ing111",
    newUsersConfig,
    articleTagsConfig,
    nicknameConfig,
    footerConfig,
    challengeConfig,
    galleryConfig,
    logoutConfig,
    resetPasswordConfig,
    leaderboardConfig,
    socialShareConfig,
    whiteListSignUpConfig,
    ambassadorSettingsConfig,
    dashboardSettingsConfig,
    dashboardPlacesConfig: {
        homePage: ["dashboard_v3_testing"],
        articles: ["articles_header"],
        benefits: ["benefits_header"],
        challenges: ["challenges_header"],
        events: ["events_header"],
        gallery: ["gallery_header"],
        leaderBoard: ["leaderboard_header"],
        mySpace: ["myspace_header"],
        mySpaceActivities: ["myactivities_header"],
        profile: ["profile_header"],
        webShop: ["webshop_header"],
        contact: []
    },
    navbarConfig,
    requiredProfileFields: [
        "givenName",
        "familyName",
        "birthDate"
    ],
    disableIconsOnRedirectionButtons: true,
    dailyOverviewConfig
};
