import { ProfileConfig } from "@energylab/shared-components/constants/config/profileConfig";
import { profileStructure } from "../profileStructure";

export const profileConfig: ProfileConfig = {
    requiredProfileFields: [
        "givenName",
        "familyName",
        "birthDate",
        "sex",
        "role"
    ],
    structure: profileStructure,
    valuesToForm: (values) => {
        if (!values) {
            return {};
        }

        const profile = values.profile || {};
        return {
            givenName: values.givenName,
            familyName: values.familyName,
            birthDate: profile.birthDate,
            sex: profile.sex,
            nationality: profile.nationality,
            locale: values.locale,
            role: profile.role
        };
    }
};
