import "@energylab/shared-components/assets/icons/enlab.font.js";
import { AppWrapper } from "@energylab/shared-components/components/appWrapper/appWrapper";
import "./style/fonts/INGMeCyWeb/INGMeCyWeb.css"
import "./style/fonts/arialCE/arialCE.css"
import { ANTD_THEME, theme } from "./style/theme";
import { createInitialStore } from "./utils/createInitialStore";
import {type FC} from "react";
// eslint-disable-next-line import/no-extraneous-dependencies
import { translations as getTranslations } from "./translations/translations";
import React from "react";

export interface AppProps {

}


const initialStore = createInitialStore();
const translations = getTranslations();

const AppComponent: FC<AppProps> = () => {

    return (
        <AppWrapper
            translations={translations}
            initialStore={initialStore}
            theme={{
                overrides: theme,
                antd: ANTD_THEME
            }}
        />
    );
};

export const App = AppComponent;
